import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    echarts: null,
    options: null,
    currentLayer: "jb",
  },
  getters: {},
  mutations: {
    setEcharts(state, echarts) {
      state.echarts = echarts;
    },
    setOptions(state, options) {
      state.options = options;
    },
    setCurrentLayer(state, currentLayer) {
      state.currentLayer = currentLayer;
    },
  },
  actions: {},
  modules: {},
});
